<!-- 答题详情 -->
<style lang="scss" scoped>
.page{
    position: relative;
	width: 100%;
    height: 100%;
    padding-bottom: 80px;
    box-sizing: border-box;
}
.content{
	padding: 32px 0px 10px;
    overflow: auto;
    height: 100%;
    box-sizing: border-box;
	.questions{
		width: 100%;
		.type{
            font-weight: bold;
            color: #222222;
            margin-bottom: 10px;
            font-size: 18px;
            line-height: 34px;
		}
		.topic{
			margin-top: 10px;
            font-size: 16px;
            color: #222222;
            line-height: 26px;
            margin-bottom: 10px;
			.img{
                margin-top: 20px;
                max-width: 120px;
                max-height: 80px;
                display: block;
			}
		}
		.essay{
			.title{
				margin-top: 32px;
				font-size: 32px;
				font-weight: bold;
				color: #222222;
				line-height: 45px;
				text-align: center;
			}
			.essayImg{
				margin-top: 24px;
				width: 100%;
			}
		}
		.essayAnswer{
			width: 100%;
			background: rgba(29,187,98,0.1);
			border-radius: 14px;
			padding: 32px;
			.grade{
				font-size: 32px;
				font-weight: bold;
				color: $comGreen;
				line-height: 45px;
				display: flex;
				align-items: center;
				.iconfont{
					margin-right: 24px;
					display: none;
				}
				&.correct{
					.dui{
						display: inline-block;
					}
				}
				&.mistake{
					color: $comRed;
					.cuo{
						display: inline-block;
					}
				}
			}
			.p{
				margin-top: 24px;
				font-size: 28px;
				font-weight: 400;
				color: #444444;
				line-height: 39px;
			}
		}
		.li{
            font-size: 16px;
			color: #444444;
			line-height: 40px;
			padding: 6px 14px;
			background-color: #F6F6F6;
			margin-bottom: 10px;
			border-radius: 14px;
			.number{
				width: 40px;
				margin-right: 10px;
				text-align: center;
			}
			.iconfont{
				display: none;
			}
			.val{
				flex: 1;
			}
			&.opt{
				background-color:rgba(1,161,253,0.1);
				color: $comBlue;
			}
			.answerImg{
				width: 160px;
				height: 160px;
			}
			&.correct{
				background: rgba(29,187,98,0.1);
				font-weight: bold;
				color: $comGreen;
				.number{
					display: none;
				}
				.dui{
					display: inline-block;
					width: 20px;
					margin-right: 19px;
					font-size: 22px;
					font-weight: initial;
					color: $comGreen;
				}
			}
			&.mistake{
				background: rgba(255,77,79,0.1);
				font-weight: bold;
				color: $comRed;
				.number{
					display: none;
				}
				.cuo{
					display: inline-block;
					width: 20px;
					margin-right: 19px;
					font-size: 22px;
					font-weight: initial;
					color: $comRed;
				}
			}
		}
		.subtopic-item{
			.type{
				font-size: 16px;
			}
		}
	}
	.encourageImg{
		width: 576px;
		height: 151px;
		display: block;
	}
	.emphasis{
        background: #FDFDFD;
        border-radius: 14px;
        border: 1px solid #BDCADC;
        padding: 18px;
        margin-bottom: 14px;
		.h2{
            height: 30px;
            font-size: 16px;
            font-weight: bold;
            color: #222222;
            line-height: 30px;
            margin-bottom: 10px;
			.iconfont{
                font-weight: initial;
                color: #FFFFFF;
                width: 26px;
                height: 26px;
                font-size: 14px;
                line-height: 26px;
                text-align: center;
                background: linear-gradient(146deg, #00D0FA 0%, #00A1FA 100%);
                border-radius: 50%;
                margin-right: 10px;
			}
		}
		.h4{
            // height: 26px;
            font-size: 16px;
            font-weight: 400;
            color: #1DBB62;
            line-height: 26px;
            margin-bottom: 6px;
		}
		.p{
			font-size: 14px;
			font-weight: 400;
			color: #444444;
			line-height: 26px;
		}
	}
}
</style>
<template>
    <div class="page">
		<div class="content">
			<div class="questions" v-if="practiceItem.syque_typ_name">
				<div class="type flex_bet_align">
					{{practiceItem.syque_typ_name}}
					<el-button type="custom_primary" @click="$router.go(-1)" size="medium">返回</el-button>
				</div>
				<div class="topic">
					<div class="flex_1 richinline" v-html="practiceItem.teles_pra_title"></div>
                    <el-image v-if="practiceItem.teles_pra_title_image" style="width: 100px; height: 100px" :src="practiceItem.teles_pra_title_image" :preview-src-list="[practiceItem.teles_pra_title_image]"  > </el-image>
				</div>
				<!-- syque_typ_id   1单项选择 2多项选择 3单项填空 4多项填空 5判断 6计算 7简答 8问答  14组合提-->
				<div v-if="practiceItem.syque_typ_id==1||practiceItem.syque_typ_id==2">
					<div v-for="(item2,index2) in practiceItem.question_item" :key="index2">
						<!-- mistake错误   correct正确-->
						<div class="li flex" :class="practiceItem.tepralog_status!=3?(item2.teles_pra_ite_isanswer==10?'correct':'mistake'):''">
                            <div class="iconfont dui">&#xe6db;</div>
                            <div class="iconfont cuo">&#xe6dc;</div>
							<div class="number">{{item2.teles_pra_ite_code}}</div>
							<div class="flex_1">
								<div class="flex_1 richinline" v-html="item2.teles_pra_ite_title"></div>
                                <el-image v-if="item2.teles_pra_ite_image" style="width: 100px; height: 100px;float: left;" :src="item2.teles_pra_ite_image" :preview-src-list="[item2.teles_pra_ite_image]"  > </el-image>
							</div>
						</div>
					</div>
				</div>
				<!-- 3单项填空 4多项填空 -->
				<div v-if="practiceItem.syque_typ_id==3||practiceItem.syque_typ_id==4||practiceItem.syque_typ_id==7">
					<!-- <div class="li flex" v-if="practiceItem.tepralog_status!=3" :class="practiceItem.tepralog_status==1?'correct':'mistake'">
						<div class="iconfont dui">&#xe6db;</div>
						<div class="iconfont cuo">&#xe6dc;</div>
					</div> -->
					<el-image v-if="practiceItem.tepralog_stu_answer_img" style="width: 100px; height: 100px" :src="practiceItem.tepralog_stu_answer_img" :preview-src-list="[practiceItem.tepralog_stu_answer_img]"  > </el-image>
				</div>
				<!-- 判断题 -->
				<div v-if="practiceItem.syque_typ_id==5">
					<div v-for="(item2,index2) in practiceItem.question_item" :key="index2">
						<!-- mistake错误   correct正确-->
						<div class="li flex_content_center" :class="practiceItem.tepralog_status!=3?(item2.teles_pra_ite_isanswer==10?'correct':'mistake'):''">
                            <div class="iconfont dui">&#xe6db;</div>
                            <div class="iconfont cuo">&#xe6dc;</div>
							<div class="number">{{item2.teles_pra_ite_code}}</div>
							<div class="flex_1">
								<div class="flex_1 richinline" v-html="item2.teles_pra_ite_title"></div>
							    <el-image v-if="item2.teles_pra_ite_image" style="width: 100px; height: 100px;float: left;" :src="item2.teles_pra_ite_image" :preview-src-list="[item2.teles_pra_ite_image]"  > </el-image>
							</div>
						</div>
					</div>
				</div>
				<!-- 组合提 -->
				<div v-if="practiceItem.syque_typ_id==14">
					<div class="subtopic-item" v-for="(item2,index2) in practiceItem.question_item" :key="index2">
						<div class="type flex_align">
							{{item2.syque_typ_name}}
						</div>
						<div class="topic">
							<div class="flex_1">
								({{ index2 + 1 }})、<em class="richinline" v-html="item2.teles_pra_title"></em>
							</div>
							<el-image v-if="item2.teles_pra_title_image" style="width: 100px; height: 100px" :src="item2.teles_pra_title_image" :preview-src-list="[item2.teles_pra_title_image]"  > </el-image>
						</div>
						<!-- syque_typ_id   1单项选择 2多项选择 3单项填空 4多项填空 5判断 6计算 7简答 8问答  14组合提-->
						<div v-if="item2.syque_typ_id==1||item2.syque_typ_id==2">
							<div v-for="(item3,index3) in item2.question_item" :key="index3">
								<div class="li flex" :class="item3.tepralog_status!=3?(item3.teles_pra_ite_isanswer==10?'correct':'mistake'):''">
									<div class="iconfont dui">&#xe6db;</div>
									<div class="iconfont cuo">&#xe6dc;</div>
									<div class="number">{{item3.teles_pra_ite_code}}.</div>
									<div class="flex_1">
										<div class="flex_1 richinline" v-html="item3.teles_pra_ite_title"></div>
										<el-image v-if="item3.teles_pra_ite_image" style="width: 100px; height: 100px;float: left;" :src="item3.teles_pra_ite_image" :preview-src-list="[item3.teles_pra_ite_image]"  > </el-image>
									</div>
								</div>
							</div>
						</div>
						<!-- 3单项填空 4多项填空 -->
						<div v-if="item2.syque_typ_id==3||item2.syque_typ_id==4||item2.syque_typ_id==7">
							<!-- <div class="li flex" :class="item2.tepralog_status==1?'correct':'mistake'">
								<div class="iconfont dui">&#xe6db;</div>
								<div class="iconfont cuo">&#xe6dc;</div>
								<div class="flex_1" v-if="item3">{{item3}}</div>
							</div> -->
							<!-- <div v-for="(item3,index3) in item2.tepralog_stu_answer" :key="index3">
								<div class="li flex" :class="item2.tepralog_status==1?'correct':'mistake'">
									<div class="iconfont dui">&#xe6db;</div>
									<div class="iconfont cuo">&#xe6dc;</div>
									<div class="flex_1" v-if="item3">{{item3}}</div>
								</div>
							</div>	 -->
							<el-image v-if="item2.tepralog_stu_answer_img" style="width: 100px; height: 100px;float: left;" :src="item2.tepralog_stu_answer_img" :preview-src-list="[item2.tepralog_stu_answer_img]"  > </el-image>
						</div>
						<!-- 判断题 -->
						<div v-if="item2.syque_typ_id==5">
							<div v-for="(item3,index3) in item2.question_item" :key="index3">
								<div class="li flex_content_center" :class="item3.teles_pra_ite_isanswer==10?'correct':'mistake'">
									<div class="iconfont dui">&#xe6db;</div>
									<div class="iconfont cuo">&#xe6dc;</div>
									<div class="number">{{item3.teles_pra_ite_code}}.</div>
									<div class="flex_1">
										<div class="flex_1 richinline" v-html="item3.teles_pra_ite_title"></div>
										<el-image v-if="item3.teles_pra_ite_image" style="width: 100px; height: 100px;float: left;" :src="item3.teles_pra_ite_image" :preview-src-list="[item3.teles_pra_ite_image]"  > </el-image>
									</div>
								</div>
							</div>
						</div>
						<div class="emphasis" v-if="item2.syque_typ_id!=14">
							<div class="h2 flex_align">
								<div class="iconfont">&#xe6d9;</div>
								答案解析
							</div>
							<div class="h4">正确答案：<span v-for="(item4,index4) in item2.sure_answer" :key="index4"> <span v-if="index4 > 0">,</span><em class="richinline" v-html="item4"></em></span></div>
							<div class="p richblock" v-if="item2.teles_pra_analysis" v-html="item2.teles_pra_analysis"></div>
							<div class="noexplain" v-else>暂无数据</div>
						</div>
						<div class="emphasis" v-if="item2.teles_kno_title&&item2.syque_typ_id!=14">
							<div class="h2 flex_align">
								<div class="iconfont">&#xe6da;</div>
								知识点
							</div>
							<div class="p">{{item2.teles_kno_title}}</div>
						</div>
					</div>
				</div>
			</div>
			<div class="emphasis" v-if="practiceItem.syque_typ_id!=14">
				<div class="h2 flex_align">
					<div class="iconfont">&#xe6d9;</div>
					答案解析
				</div>
				<div class="h4">正确答案：<span v-for="(item4,index4) in practiceItem.sure_answer" :key="index4"> <span v-if="index4 > 0">,</span><em class="richinline" v-html="item4"></em></span></div>
				<div class="p richblock" v-if="practiceItem.teles_pra_analysis" v-html="practiceItem.teles_pra_analysis"></div>
				<div class="noexplain" v-else>暂无数据</div>
			</div>
			<div class="emphasis" v-if="practiceItem.teles_kno_title&&practiceItem.syque_typ_id!=14">
				<div class="h2 flex_align">
					<div class="iconfont">&#xe6da;</div>
					知识点
				</div>
				<div class="p">{{practiceItem.teles_kno_title}}</div>
			</div>
		</div>
		<div class="flotageBtn bg-c-f">
			<div class="combtn1" v-if="optIndex==0">上一题</div>
			<div class="combtn3" v-else @click="changePractice(optIndex,-1)">上一题</div>
			<div class="combtn1" v-if="optIndex==practiceList.length-1">下一题</div>
			<div class="combtn3" v-else @click="changePractice(optIndex,1)">下一题</div>
		</div>
	</div>
</template>

<script>
import { practice_list } from "@api/home"
export default {
    name: 'questionsDetails',
    data() {
        return {
            //选中下标
            optIndex:0,
            //授课课堂id
            tecla_id:0,
            //课题列表
            practiceList:[],
            //单题内容
            practiceItem:{},
        }
    },
    created() {
        this.optIndex=this.$route.params.index
        this.tecla_id=this.$route.params.id
        this.getData();
    },
    methods: {
       
        // 获取数据
        async getData() {
            let { data } = await practice_list({tecla_id:this.tecla_id});
            this.practiceList=data
            this.practiceItem=data[this.optIndex]
            this.$forceUpdate();
        },
        //切换题
        changePractice(optIndex,number){
            this.optIndex=parseInt(optIndex)+number
            this.practiceItem=this.practiceList[this.optIndex]
        },
      
    },
}
</script>